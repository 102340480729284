import React, { useEffect, useState, useContext } from 'react';
import { auth } from '../firebase';

export type CustomClaims = {
  roles: string[];
  regions: string[];
};
interface AppContextInterface {
  currentUser: firebase.User | null | undefined;
  userClaims: CustomClaims | undefined;
}

export const AppContext = React.createContext<AppContextInterface>({
  currentUser: undefined,
  userClaims: undefined,
});

export const useAppContext = () => useContext(AppContext);

interface IAppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<
    null | undefined | firebase.User
  >();
  const [userClaims, setUserClaims] = useState<CustomClaims>();

  useEffect(() => {
    auth.onAuthStateChanged(async auth => {
      setCurrentUser(auth);

      if (auth) {
        const token = await auth.getIdTokenResult();
        setUserClaims(token?.claims as CustomClaims);
      }
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        currentUser,
        userClaims,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
