import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import DOMPurify from 'dompurify';

export const sanitiseScore = (num: any) =>
  Number.isFinite(num) ? Math.round(num * 100) : undefined;

export const sortVotes = (votes: any) => {
  const sortedVotes = _sortBy(votes, ['firstName', 'lastName']);

  const groupedVotes = {
    Yes: [],
    Maybe: [],
    No: [],
    ..._groupBy(sortedVotes, 'vote'),
  };

  return [...groupedVotes.Yes, ...groupedVotes.Maybe, ...groupedVotes.No];
};

export const getDomain = (url?: string) =>
  typeof url === 'string'
    ? url
        .replace(/https?:\/\//, '')
        .replace('www.', '')
        .split('/')[0]
    : '';

export const lineBreakToBr = (str?: string) =>
  typeof str === 'string'
    ? DOMPurify.sanitize(str.replace(/\n\s*\n/g, '<div class="spacer"></div>'))
    : '';

export function makeId(length: number) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
