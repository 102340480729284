import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  MuiThemeProvider as ThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import Theme from './Theme';

import CustomBrowserRouter from 'util/CustomBrowserRouter';
import PrivateRoute from 'util/PrivateRoute';
import { AppProvider } from 'contexts/appContext';

import {
  ErrorBoundary,
  EmptyState,
  Loading,
} from '@antlerengineering/components';
import { SnackProvider } from 'samosas';
import ForgotPasswordView from 'views/auth/ForgotPasswordView';
import ResetPasswordView from 'views/auth/ResetPasswordView';
import AuthLinkView from 'views/auth/AuthLinkView';
import GoogleAuthView from 'views/auth/GoogleAuthView';
import SignInView from 'views/auth/SignInView';
import AdminAuthView from 'views/auth/AdminAuthView';
import FundManagerCEView from 'views/FundManagerCEView';

import SignOutView from 'views/SignOutView';
import PortfolioShortlistView from 'views/PortfolioShortlistView';
import SFDRNordicFundView from 'views/SFDRNordicFundView';
import FundManagerNLView from 'views/FundManagerNLView';

const DDView = lazy(() => import('views/DDView'));
const ICView = lazy(() => import('views/ICView'));
const PortfolioView = lazy(() => import('views/PortfolioView'));
const FounderApplicationView = lazy(() =>
  import('views/FounderApplicationView')
);
const OurStartupsView = lazy(() => import('views/OurStartupsView'));
const FishingView = lazy(() => import('views/FishingView'));

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <ErrorBoundary>
        <AppProvider>
          <SnackProvider>
            <CustomBrowserRouter>
              <Suspense fallback={<Loading fullScreen />}>
                <Switch>
                  <Route
                    exact
                    path={'/adminAuth'}
                    render={() => <AdminAuthView />}
                  />
                  <Route
                    exact
                    path={'/authLink'}
                    render={() => <AuthLinkView />}
                  />
                  <Route
                    exact
                    path={'/googleAuth'}
                    render={() => <GoogleAuthView />}
                  />
                  <Route
                    exact
                    path={'/forgotPassword'}
                    render={() => <ForgotPasswordView />}
                  />
                  <Route
                    exact
                    path={'/investmentsAndIdeas'}
                    render={props => <FishingView {...props} />}
                  />
                  <Route
                    exact
                    path={'/resetPassword'}
                    render={() => <ResetPasswordView />}
                  />
                  <Route exact path="/auth" render={props => <SignInView />} />
                  <Route exact path="/signOut" render={() => <SignOutView />} />
                  <Route
                    exact
                    path="/shortList/:id"
                    render={props => <PortfolioShortlistView {...props} />}
                  />
                  <Route
                    exact
                    path={'/fundManagerCE'}
                    render={props => <FundManagerCEView {...props} />}
                  />
                  <Route
                    exact
                    path={'/fundManagerNL'}
                    render={props => <FundManagerNLView {...props} />}
                  />
                  <Route
                    exact
                    path={'/NordicFund'}
                    render={props => <SFDRNordicFundView {...props} />}
                  />
                  <PrivateRoute
                    exact
                    path="/DD/:teamId"
                    render={props => <DDView {...props} />}
                  />
                  <PrivateRoute
                    exact
                    path="/IC/:cohort"
                    render={props => <ICView {...props} />}
                  />
                  <PrivateRoute
                    exact
                    path="/portfolio/:id"
                    render={props => <PortfolioView {...props} />}
                  />
                  <PrivateRoute
                    exact
                    path="/founderApplication/:id"
                    render={props => <FounderApplicationView {...props} />}
                  />
                  <Route
                    exact
                    path="/our-startups/:teamName"
                    render={props => <OurStartupsView {...props} />}
                  />
                  <Route
                    render={() => (
                      <EmptyState
                        message="Page Not Found"
                        fullScreen
                        id="PageNotFound"
                      />
                    )}
                  />
                </Switch>
              </Suspense>
            </CustomBrowserRouter>
          </SnackProvider>
        </AppProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
