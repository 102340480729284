import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
  makeStyles,
  createStyles,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import AntlerLogo from 'assets/logo.svg';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      backgroundColor: '#fff',
    },
    root: {
      height: '100%',
      padding: theme.spacing(3),
      margin: 0,
      width: '100%',
    },
    logo: {
      display: 'block',
    },
    logoText: {
      color: '#595959',
      fontSize: '1.5rem',
      fontWeight: 'bolder',
      paddingLeft: '20px',
      ['@media (max-width:780px)']: {
        display: 'none',
      },
    },
    paper: {
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
    },
    mainsec: {
      padding: theme.spacing(2),
    },
    content: {
      textAlign: 'left',
    },
    h1: {
      fontSize: '1.5rem',
      fontWeight: 'bolder',
    },
    h2: {
      fontSize: '1rem',
      fontWeight: 'bolder',
    },
    modalh1: {
      fontSize: '1.5rem',
      fontWeight: 'bolder',
      color: '#ED4747',
    },
    dwnldbtn: {
      paddingTop: '10px',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalPaper: {
      position: 'absolute',
      width: 450,
      ['@media (max-width:780px)']: {
        width: 325,
      },
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '25px',
    },
    contactSection: {
      paddingTop: '20px',
    },
    logoButton: {
      '&:hover': {
        background: 'transparent',
      },
    },
  })
);

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

export default function SFDRNordicFundView({
  history,
  location,
}: RouteComponentProps) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [regionState, setRegionState] = useState('');
  const [professionalLevelState, setProfessionalLevelState] = useState('');
  const [modalStyle] = React.useState(getModalStyle);

  document.title = 'Antler Nordic Funds Sustainability-Related Disclosures';

  const setRegion = (e: any) => {
    e.preventDefault();
    setRegionState(e.currentTarget.value);
  };

  const setProfessionalLevel = (e: any) => {
    e.preventDefault();
    setProfessionalLevelState(e.currentTarget.value);
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.logoButton}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <img
              src={AntlerLogo}
              alt="Antler"
              width="125px"
              height="35px"
              className={classes.logo}
            />
            <div className={classes.logoText}>
              Antler Nordic Funds Sustainability-Related Disclosures
            </div>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container>
        <Grid
          container
          spacing={2}
          className={classes.mainsec}
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <div className={classes.content}>
                <div className={classes.h1}>
                  Sustainability-related disclosures pursuant to Regulation (EU)
                  2019/2088 (“SFDR”) regarding Antler Nordic Fund II AB and
                  Antler Nordic Fund II Fund Manager AB
                </div>
                <div className={classes.dwnldbtn}>
                  <a
                    href={require('assets/SFDRN.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Button color="primary" size="large" variant="contained">
                      Download sustainability-related disclosures
                    </Button>
                  </a>
                </div>
                <br />
                <div className={classes.h1}>
                  Sustainability-related disclosures pursuant to Regulation (EU)
                  2019/2088 (“SFDR”) regarding Antler Europe Fund I AB and
                  Antler Europe Fund I AB Fund Manager
                </div>
                <div className={classes.dwnldbtn}>
                  <a
                    href={require('assets/SFDR.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Button color="primary" size="large" variant="contained">
                      Download sustainability-related disclosures
                    </Button>
                  </a>
                </div>
                <br />
                {/* EuVECA Pre-Investment Disclosure Antler Nordic Fund II AB */}
                <div className={classes.h1}>
                  EuVECA Pre-Investment Disclosure Antler Nordic Fund II AB
                </div>
                <div className={classes.dwnldbtn}>
                  <a
                    href={require('assets/EuVECA-PID.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Button color="primary" size="large" variant="contained">
                      Download EuVECA Pre-Investment Disclosure
                    </Button>
                  </a>
                </div>
                <br />

                {/* Faktablad (KID) Antler Nordic Fund II AB (in Swedish)*/}
                <div className={classes.h1}>
                  Faktablad (KID) Antler Nordic Fund II AB (in Swedish)
                </div>
                <div className={classes.dwnldbtn}>
                  <a
                    href={require('assets/KIDNordicSwedish.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Button color="primary" size="large" variant="contained">
                      Download KID (in Swedish)
                    </Button>
                  </a>
                </div>
                <br />
                {/* Key Information Document (KID) Antler Nordic Fund II AB (in English)*/}
                <div className={classes.h1}>
                  Key Information Document (KID) Antler Nordic Fund II AB (in
                  English)
                </div>
                <div className={classes.dwnldbtn}>
                  <a
                    href={require('assets/KIDNordicEn.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Button color="primary" size="large" variant="contained">
                      Download KID (in English)
                    </Button>
                  </a>
                </div>
                <br />
                {/* Dokument med central investorinformation (KID) Antler Nordic Fund II AB (in Danish)
                 */}
                <div className={classes.h1}>
                  Dokument med central investorinformation (KID) Antler Nordic
                  Fund II AB (in Danish)
                </div>
                <div className={classes.dwnldbtn}>
                  <a
                    href={require('assets/KIDNordicDanish.pdf')}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <Button color="primary" size="large" variant="contained">
                      Download KID (in Danish)
                    </Button>
                  </a>
                </div>
                <br />

                <p>
                  For more information on Antler Nordics visit our{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.antler.co/location/nordics"
                  >
                    webpage.
                  </a>
                </p>
                <div className={classes.h2 + ' ' + classes.contactSection}>
                  Contact
                </div>
                <div>
                  c/o Antler
                  <br />
                  Box 16391
                  <br />
                  103 27 Stockholm
                  <br />
                  <a href="mailto:nordic-fund@antler.co">
                    nordic-fund@antler.co
                  </a>
                  <br />
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.modalPaper}>
          <form>
            <div>
              <FormControl>
                <div className={classes.modalh1}>Origin </div>
                <RadioGroup onChange={e => setRegion(e)}>
                  <FormControlLabel value="EU" control={<Radio />} label="EU" />
                  <FormControlLabel
                    value="Non-EU"
                    control={<Radio />}
                    label="Non-EU"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <br />

            <div>
              <FormControl>
                <div className={classes.modalh1}>Qualification of investor</div>
                <RadioGroup onChange={e => setProfessionalLevel(e)}>
                  <FormControlLabel
                    value="Professional"
                    control={<Radio />}
                    label="Professional"
                  />
                  <FormControlLabel
                    value="Semi-Professional"
                    control={<Radio />}
                    label="Semi-Professional"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </form>

          <div>
            {regionState === 'EU' &&
            professionalLevelState === 'Semi-Professional' ? (
              <a
                href={require('assets/KID.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <div className={classes.dwnldbtn}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={e => handleOpen()}
                  >
                    Download Key Information Document
                  </Button>
                </div>
              </a>
            ) : (
              <div className={classes.dwnldbtn}>
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  disabled
                >
                  Download Key Information Document
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
